import React from 'react'
import PropTypes from 'prop-types'
import SanityImage from 'gatsby-plugin-sanity-image-alt'
import loadable from '@loadable/component'
import { useWindowSize } from '../helpers/useWindowSize'
import { useSanityImageShape } from '../helpers/useSanityImageShape'

const PortableText = loadable(() => import('./PortableText'))

const PageBuilderReviews = ({ block, raw, index }, props) => {
  const { specific } = block

  const windowSize = useWindowSize()

  const size = windowSize.width <= 768 ? 170 : 125

  return (
    <section
      className={`container grid grid-cols-12 mx-auto my-8 md:my-12  md:pb-16 relative ${
        index === 0 ? `mt-16 md:mt-20` : ``
      }`}
    >
      <div className="col-span-12 xl:col-span-10 xl:col-start-2">
        {specific && (
          <>
            <div className="grid grid-cols-12 lg:grid-cols-9 mx-auto pb-8 relative text-left md:gap-8">
              {specific.map((testimonial, index) => {
                const imageShaped = useSanityImageShape(testimonial.image)
                return (
                  <div
                    key={index}
                    className={`col-span-12 md:col-span-6 lg:col-span-3 ${
                      index === 0 ? 'mt-4' : 'mt-8 md:mt-4'
                    }`}
                  >
                    <div className="md:flex-shrink-0 md:mr-8">
                      {testimonial.image && (
                        <div style={{ width: size }}>
                          {imageShaped && (
                            <SanityImage
                              {...imageShaped}
                              className="rounded-full"
                              alt={testimonial.image.alt}
                              width={size}
                              height={size}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="mt-6">
                      {testimonial && (
                        <div className="md:pr-4 lg:pr-8">
                          <PortableText blocks={testimonial.body[0]} />
                          <div className="prose mt-4">
                            <p>- {testimonial.author}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

PageBuilderReviews.propTypes = {
  block: PropTypes.object,
  raw: PropTypes.object,
  index: PropTypes.number,
}

PageBuilderReviews.defaultProps = {
  block: {},
  raw: {},
  index: 0,
}

export default PageBuilderReviews
